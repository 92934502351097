.page.landing{
  height: 100%;
  background-color:black;
  .button-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    button.more-info{
      top: 2vh;
      left: 2vh;
      display:block;
      height:max(4vw, 4vh);
      width:max(4vw, 4vh);
      position: relative;
      background-color: #aaa;
      color: transparent;
      background-color: transparent;
      border-width: 0;
      transition: .3s ease-in-out;

      &::before{
        position: absolute;
        left: 43%;
        top: 0%;
        display: block;
        content: ' ';
        width: 14%;
        height: 100%;
        background-color: gold;
      }
      &::after{
        position: absolute;
        left: 0%;
        top: 43%;
        display: block;
        content: ' ';
        width: 100%;
        height: 14%;
        background-color: gold;
      }
      &.visible{
        transform: rotate(45deg);
        left: 85vw;

        &::before, &::after{
          background-color: white;
        }
      }
    }
  }

  .logo-box, .ring{
    position: absolute;
    height: min(80vw, 80vh);
    width: min(80vw, 80vh);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .logo-box{
    display: flex;
    justify-content: center;
    align-items: center;
    .logo{
      height: 1.5em;
      isolation: isolate;
      color: white;
      font-size: min(10vw, 10vh);
      font-family: 'Roman Caps';
      filter: brightness(200%);
      overflow: hidden;
      background-color:black;

      .text {
        background-color: black;
        color: #c9adad;
        font-size: min(15vw, 15vh);
        padding: .5vw;
        text-transform: uppercase;
        user-select: none;
        em{
          border-top: 2px solid white;
          border-bottom: 2px solid white;
        }
      }

      .text::before {
        content: 'CVD';
        position: absolute;
        color: white;
        filter: blur(0.02em);
        mix-blend-mode: difference;
      }

      .gradient {
        position: absolute;
        background: linear-gradient(45deg, red, gold, #eecc90, gold, red);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        mix-blend-mode: multiply;
      }

      .spotlight {
        // position: absolute;
        display:block;
        width: 200%;
        height: 200%;
        margin-top: -85%;
        margin-left: -100%;
        top: -100%;
        left: -100%;
        right: 0;
        bottom: 0;
        background:
            radial-gradient(
                circle,
                white,
                transparent 25%
            ) center / 25% 25%,
            radial-gradient(
                circle,
                white,
                black 25%
            ) center / 12.5% 12.5%;
        animation: light 5s linear infinite;
        mix-blend-mode: color-dodge;
      }
    }
  }

  .ring{
    display: block;
    overflow: hidden;

    p{
      color:white;
    };

    .text{
      position: absolute;
      top: 0%;
      right: 0%;
      width: 100%;
      height: 100%;
      filter: brightness(200%);
      animation: rotation 20s infinite linear;
      text-shadow: 1px 1px #558abb;

      svg{
        overflow: visible;
        margin-left: 13%;
        margin-top: 13%;
        display:block;
        width: 140%;
        filter: blur(0.06em);

        text {
          fill: white;
          font-size: 80%;
          textPath {
            font-family: 'Roman Caps';
          }
        }
      }
    }

    .gradient, .spotlight, .reverse {
      position: absolute;
      top: 0%;
      right: 0%;
      width: 100%;
      height: 100%;
    }

    .gradient {
      background: linear-gradient(45deg, red, gold, lightgreen, gold, red);
      mix-blend-mode: multiply;
    }

    .reverse {
      background:conic-gradient(from 90deg at 50% 50%, rgba(0, 0, 0, 1) 1%, rgba(255, 255, 255, 1) 9%, rgba(0, 0, 0, 1) 18%, rgba(255, 209, 1, 1) 26%, rgba(0, 0, 0, 1) 34%, rgba(255, 255, 255, 1) 43%, rgba(0, 0, 0, 1) 51%, rgba(233, 116, 30, 1) 60%, rgba(247, 247, 247, 1) 67%, rgba(0, 0, 0, 1) 75%, rgba(255, 255, 255, 1) 82%, rgba(205, 220, 57, 1) 91%, rgba(0, 0, 0, 1) 99%);

      mix-blend-mode: color-dodge;
      animation: rotation 40s infinite linear;
      animation-direction: reverse;
    }

  }
  .overlay{
    position:absolute;
    top: 8%;
    left: -90%;
    width:90%;
    height: 82%;
    border-radius: 1vw;
    background: #222;
    transition: .3s ease-in-out;

    >div{
      position: absolute;
      margin-right: 10vw;
    }

    .top{
      top: 8%;
    }
    .middle{
      top: 50%;
      transform: translateY(-50%);
      padding-top: 4vh;
    }
    .bottom{
      bottom: 8%;
    }

    font-family: 'Sofia Sans Condensed', sans-serif;
    color: white;
    padding: 10vw;

    em{
      font-size: min(12vh, 16vw);
    }
    p{
      font-size: min(6vh, 8vw);;
    }
    span{
      display: block;
      height: 8vh;
    }
    a{
      color: white;
    }

    &.visible{
      left: 0%;
    }
  }

  $quantity: 25;
  .particles {
    .particle{
      position: fixed;
      left: 50%;
      top: 50%;
      width: 1.4vw;
      height: 1.4vw;
      margin: -0.2vw 0 0 9.8vw;
      animation: ease 200s alternate infinite;
      pointer-events: none;

      &::before,
      &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform-origin: -10vw;
      }

      &::before{
        background: black;
        opacity: 0.4;
        animation: drift ease alternate infinite;
      }

      &::after{
        background: white;
        opacity: 0;
        box-shadow: 0 0 0vw 0vw yellow;
        animation: drift ease alternate infinite, flash ease infinite;
      }
    }

    @for $i from 1 through $quantity {

      $steps: random(12) + 16;
      $rotationSpeed: random(10) + 8s;

      .particle:nth-child(#{$i}) {
        animation-name: move#{$i};

        &::before {
          animation-duration: #{$rotationSpeed};
        }

        &::after {
          animation-duration: #{$rotationSpeed}, random(6000) + 5000ms;
          animation-delay: 0ms, random(8000) + 500ms;
        }
      }

      @keyframes move#{$i} {
        @for $step from 0 through $steps {
          #{$step * (100 / $steps)}% {
            transform: translateX(random(100) - 50vw) translateY(random(100) - 50vh) scale(random(75) / 100 + .25);
          }
        }
      }
    }
  }
}

@keyframes light {
  to {
    transform: translate(50%, 50%);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}
